.confirmation {
    width: 100vw;
    height: 100vh;
    background: rgba(217, 217, 217, 0.92);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; 
    position: fixed;
    top:0;
    left: 0;
    gap: 5px;
}
.logout-confirm{
    background-color: white;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border-radius: 4px;
}
.logout-text{
    margin-top: 1rem;
    font-weight: 600;
    font-size: 1em;

}
.logout-info{
    color: rgba(108, 117, 125, 1);
    font-size: 0.8rem;
}
.cancel, .proceed{
    border: none;
    border-radius: 2px;
    font-size: 0.9rem;
}
.buttons{
    display: flex;
    flex-direction: row;
    gap: 1rem
}
.cancel{
    padding:0.3rem 2rem;
    background: rgba(255, 255, 255, 1);
    border: 2px solid rgba(41, 48, 66, 1)
}
.proceed{
    padding:0.3rem 3rem;
    background: rgba(63, 188, 232, 1);
    border: 1px solid rgba(63, 188, 232, 1);
    color:white;
}
