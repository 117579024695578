.teams-container {
    display: flex;
    flex-direction: column;
    height: 100%;
 }
.teams-title {
    text-align: center;
    margin-left: 5rem;
}
.domainData{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:2rem;
}
.dropdown-toggle{
    display: flex;
    justify-content: center;
    align-items: center;
}
.teams-label {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #293042;
    padding-left: 5px;
}

.teams-login-container {
    padding: 1rem 2rem 1rem 2rem;
    width: 400px;
    border-radius: 2%;
    margin-left: 2rem;
    box-shadow:
        0px 0px 0px 0px #21212108,
        0px 3px 6px 0px #21212108,
        0px 11px 11px 0px #21212108,
        0px 25px 15px 0px #21212105,
        0px 44px 18px 0px #21212103,
        0px 69px 19px 0px #21212100;
    background-color: #F8F8F8;
    display: flex;
    flex-direction: column;
    gap: 7px
}
.teamslogo{
    width: 2rem;
    height: 2rem;
    margin: 0 5px;
}
.teams-login-button {
    align-items: center;
    display: flex;
    justify-content: center;
    background: white;
    padding: 12px 8px;
    border-radius: 10px;
    border: 1px solid #CCCCCC;
    font-size: 14px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: "Poppins";
    font-weight: 500;
    text-align: center;

}

.teams-login-button::before,
.teams-login-button::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    border: 1.5px solid transparent;
    box-sizing: border-box;
    transition: all 0.4s ease;
}

.teams-login-button::before {
    border-right-color: rgba(63, 188, 232, 1);
    border-top-color: rgba(63, 188, 232, 1);
    transform: scaleX(0);
    transform-origin: right;
}

.teams-login-button::after {
    border-bottom-color: rgba(63, 188, 232, 1);
    border-left-color: rgba(63, 188, 232, 1);
    transform: scaleY(0);
    transform-origin: bottom;
}

.teams-login-button:hover::before {
    transform: scaleZ(1);
}

.teams-login-button:hover::after {
    transform: scaleY(1);
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    font-family: "Poppins";
    font-size: 8px;
    font-weight: 400;
    line-height: 11px;
    text-align: center;
    margin: 0.8rem 0;
    
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #a19d9d;
}

.separator:not(:empty)::before {
    margin-right: 3em;
}

.separator:not(:empty)::after {
    margin-left: 3em;
}
.slack-login {
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    width: 44.5%
}
@media (min-width: 1380px){
    .teams-login-container {
        padding: 2em 3rem 2rem 3rem;
    }
    .separator {
        margin: 0.5rem 0;
    }
}

@media (min-width: 768px) and (max-width: 1379px){
   
    .teams-login-container{
        width:100%;
    }
 }
.spinner-gif{
    width:70px;
    height:70px;
}
.login-other-account-title{
    font-size: small;
    padding: 0.5rem 0rem 1.5rem 0rem;
    text-align: center;
}
.login-other-account-button{
    align-items: center;
    display: flex;
    justify-content: center;
    background: black;
    color:white;
    padding: 12px 8px;
    border-radius: 10px;
    border: none;
    font-size: 14px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    font-weight: 500;
    text-align: center;
    
}