@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Poppins", sans-serif;

}

.metrics-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 1rem 3rem;
}

.metric-card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 0.6rem;
    padding: 1em 0px;
    margin: 0 0.5rem;
    text-align: center;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    transition: transform 0.2s;
}

.metric-icon {
    width: 1.5em;
    height: 1.3em;
    margin-right: 5px;
}

/* .metric-card:hover {
    transform: translateY(-5px);
} */

.metric-card h3 {
    font-size: 1em;
    font-weight: 500;
    margin-bottom: 0px;
}

.metric-text {
    display: flex;
    padding: 4px;
    margin-bottom: 0.6rem;
    align-items: center;
    padding-bottom: 1rem;

    justify-content: center;
    border-bottom: 1px solid rgba(63, 64, 95, 0.15);
}

.metric-value {
    font-size: 1.3em;
    margin: 1rem 0;;
    font-weight: 400;
    color: #2d2d2d;
}

@media (max-width: 1379px) and (min-width: 768px) {
    .metrics-container {
        grid-template-columns: repeat(5, 1fr);
    }
    .metric-card h3 {
        font-size: 0.9em;
    }
    .metric-value {
        font-size: 1.3em;
    }

}
@media (min-width: 1380px){
    .metric-value {
        font-size: 1.5em;
    }
    .metric-card h3 {
        font-size: 1.3em;
    }

}

@media (max-width: 767px) {
    .metrics-container {
        grid-template-columns: repeat(5, 1fr);
        padding: 0.7rem;
    }

    .metric-card {
        padding: 0.5em 0;
        border-radius: 0.4em;
    }

    .metric-card h3 {
        font-size: 0.7em;
    }

    .metric-text {
        padding: 2px;
        margin-bottom: 0.6rem;
    }

    .metric-value {
        font-size: 0.7em;
    }

    p {
        margin-bottom: 0;
    }
}

@media (max-width: 480px) {
    .metrics-container {
        grid-template-columns: repeat(5, 1fr);
        padding: 0.5rem;
    }

    .metric-card {
        padding: 0.3em 0;
        border-radius: 5px;
        border: 1px solid #e0e0e0;
    }

    .metric-icon {
        width: 0.5em;
        height: 0.5em;
    }

    .metric-card h3 {
        font-size: 0.3em;
    }

    .metric-value {
        font-size: 0.3em;
    }

    p {
        margin-bottom: 0;
    }
}