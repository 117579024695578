body {
    overflow: hidden;
    font-family: "Poppins";
}

.slack-disable,
.team-disable {
    opacity: 0.6;
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 0.3rem 1rem;
}

.body {
    margin: 0px 2rem;
}

.team-tab,
.slack-tab {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 0.3rem 1rem;
    border-radius: 4px;
    border: 1px solid rgba(63, 188, 232, 1)
}

.slack-tab {
    padding: 0 1rem;
}

.team-tab:hover,
.slack-tab:hover {
    text-decoration: none;
    background: #f2f2f2;
}

.tab-navigation {
    display: flex;
    align-items: center;
    margin-top: 0.8rem;
    gap: 8px;
}

.teamstab-logo {
    width: 1rem;
    height: 1rem;
    margin-right: 3px;
}

.slacktab-logo {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 3px;
    color: rgba(128, 128, 128, 1);
}

.slacktab-diable {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 3px;
}

.teamtab-logo {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 3px;
    color: rgba(128, 128, 128, 1);
}

.logged-in {
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-weight: 500;
    font-style: italic;
}

.rightArrowIcon {
    cursor: pointer;
    margin-left: 5px
}

.slacklogo-disable {
    width: 1.3rem;
    height: 1.3rem;
    color: white;
    border: 1px solid rgba(128, 128, 128, 1);
}

.tabtext {
    font-size: 1rem;
    font-weight: 500;
}

.slackusertext,
.teamstext {
    color: rgba(63, 188, 232, 1);
    font-weight: 500;
    margin-right:4px;
}

.metrics {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 0.5rem;
}

.loggedIn-user {
    color: rgba(63, 188, 232, 1);
}

.teamstext,
.slackusertext,
.loggedIn-user {
    cursor: text;
}

.loggedIn-metrics {
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.loggedIn-metrics,
.usermetrics,
.migratermetrics,
.workspacemetrics {
    width: 15rem;
    height: 4.6rem;
    position: relative;
    border-radius: 4px;
    box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31),
        0px 3px 8px 0px rgba(9, 30, 66, 0.16);
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: rgba(255, 255, 255, 1);
    padding: 0.5rem 0.8rem;
    gap: 5px;
    font-size: 0.8rem;
    font-weight: 600;
}

.metricsvalue {
    font-weight: 600;
    font-size: 1rem;
}

.usermetricsvalue {
    display: flex;
    justify-content: space-between;
}

.adminlogo {
    width: 1.5rem;
    height: 1.5rem;
}

.usermetricstext {
    font-size: 1rem;
    font-weight: 400;
}

.downarrowlogo {
    width: 0.8rem;
    cursor: pointer;
}

.metricssection {
    display: flex;
    align-items: center;
    gap: 10px;
}

.metricsteams {
    display: flex;
    align-items: center;
    gap: 2.5rem;
}

.teamsexpand {
    width: 16rem;
    height: 4.6rem;
    border-radius: 4px;
    box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31),
        0px 3px 8px 0px rgba(9, 30, 66, 0.16);
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: rgba(255, 255, 255, 1);
    padding: 0.8rem 1.6rem;
    gap: 5px;
    font-size: 0.8rem;
    font-weight: 600;
}

.metrics-list {
    list-style-type: disc;
    padding: 0.2rem 1.4rem;
    margin: 0;
    top: 5px;
    position: absolute;
    color: rgba(255, 255, 255, 1);
    background: rgba(63, 188, 232, 1);
    z-index: 1000;
    border-radius: 0.5rem;
    left: 5px;
    display: none;
    cursor: pointer;
}

.infoIcon {
    margin-left: 5px;
    color: rgba(63, 188, 232, 1);
    font-size: 0.7rem;
}

.sort-btn {
    font-size: 18px;
    margin-left: 5px;
    cursor: pointer;
}

.sort-btn:hover {
    background: #f2f2f2;
}

.Tooltip {
    position: relative;
    display: inline-block;
    
}

.Tooltip .tooltiptext {
    visibility: hidden;
    width: 10rem;
    font-size: 11px;
    border: 1px solid rgba(63, 188, 232, 1);
    background-color: white;
    color: rgba(128, 128, 128, 1);
    text-align: center;
    border-radius: 6px;
    padding: 4px 0;
    position: absolute;
    z-index: 1;
    top: -14px;
    left: 130%;
}

.statusbutton {  
    padding: 0.2rem 0.6rem;
    color: black;
    border-radius: 6px;
}

.Tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    /* padding: 10px; */
    margin-top: -8px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent rgba(63, 188, 232, 1) transparent transparent;
}

.Tooltip:hover .tooltiptext {
    visibility: visible;
}

.metrics-list.visible {
    display: block;
}

/* activity - log */
.activity-log {
    width: 13rem;
    height: 4.6rem;
    border-radius: 4px;
    padding: 0.3rem 0.5rem;
    background: rgba(128, 128, 128, 1);
    border: 1px solid rgba(226, 226, 231, 1);
    cursor: pointer;
}

.history-icon {
    color: #fff;
    margin-left: 1rem;
}

.activity {
    display: block;
    margin-left: 3rem;
    color: #fff;
}

.right-arrow {
    color: #fff;
    float: right;
    margin-right: 1rem;
}

.activity-feed {
    position: absolute;
    top: 3.5rem;
    right: 0px;
    background: rgba(221, 220, 221, 1);
    height: 100%;
    width: 27rem;
}

.activity-bar {
    display: flex;
    align-items: center;
}

.activity-heading {
    padding: 10px 20px;
    display: flex;
    color: rgba(41, 48, 66, 1);
    background: #fff;
    font-weight: 500;
    justify-content: space-between;
    align-items: center;
}

.days-ago {
    float: right;
    margin-right: 2%;
    font-size: 0.7em;
    margin-top: 1%;
}


.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(217, 217, 217, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.activelog {
    background-color: white;
}

.activity-content {
    align-items: center;
    overflow-y: scroll;
    height: 100vh;
    padding-bottom: 7rem;
    scrollbar-width: thin;
}
.activityDate{
    color: black;
    font-size: 9px;
}

.date-string {
    font-size: 0.8em;
    text-align: center;
    margin: 10px auto;
    color: #808080c7;
}

.act-items {
    display: flex;
    margin-bottom: 5%;
    margin-right: 2%;
}

.activity-item {
    font-size: 0.8rem;
    width: 100%;
    padding-left: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
    margin-bottom: 5px;
    color: #fff;
}

.circle-icn {
    font-size: 0.3em;
    display: block;
    margin-top: 18px;
    color: black;
    margin-left: 20px;
    margin-right: 5px;
}

/* dashboard-table */
.dashboard-table {
    background: #FFFFFF;
    box-shadow: 0px 0px 1px 0px #091E424F;
    box-shadow: 0px 3px 8px 0px #091E4229;
    border: 1px solid #3F405F26;
    width: 100%;
    margin-top: 1rem;
}

.second-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 10px 5px 20px;

}

.second-nav-left {
    display: flex;
    flex-direction: column;
    width: 50%;
}
.importSubmit {
    background: #3FBCE8;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    padding: 6px 9px;
    border-radius: 5px;
    color: white;
}
.importbutton{
    float:right;
}
.importErrorbutton{
    display:flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}
.proceedButton{
    padding:0.3rem 1rem;
    background: rgba(63, 188, 232, 1);
    border: 1px solid rgba(63, 188, 232, 1);
    color:white;
}
.user-fetch,.user-fetch-disable{
    width: 90px;
    height: 30px;
    padding: 6px 9px;
    border-radius: 7px;
    border: 0px;
    color: white;
    background: #3FBCE8;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
}
.user-fetch-disable{
    opacity: 0.5;
    cursor:None;
}
.fetch-all-users {
    font-family: "Poppins";
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    color: #3FBCE8;
    cursor: pointer;
}

.import-export {
    width: 90px;
    height: 38px;
    background: #FBFAF9;
    padding: 8px 10px;
    border-radius: 7px;
    border: 1px solid #F4F5F5;
    color: #524A3EE3;
    font-family: "Poppins";
    font-size: 13px;
    font-weight: 500;
}

.toggle-down {
    margin-left: 4px;
}

.second-nav-right {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    /* width: 70% */
}
.activityteamslogo{
    width: 2rem;
    height:2rem;
    margin-right: 9px;
}
.activityslacklogo{
    width: 3rem;
    height:3rem;
}

.second-nav-middle {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 70%
}

.user-search {
    display: flex;
    flex-direction: row;
    width: 55%;
    align-items: center;
    margin-top: 0.5rem;
    height: 2.3rem;
    margin-right: 1rem;
    border-radius: 8px;
    border: 1px solid #B7B7B7;
}

.filter {
    border-radius: 7px;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 600;
    background: none;
    width: 5rem;
    height: 2rem;
    border: 2px solid #EFEFEF;
    align-self: center;
    margin-right: 1rem;
    cursor: pointer;
}


.search-icon {
    margin-left: 10px;
}

.filter-label {
    margin-bottom: 4px;
    padding-right: 4px;
}

/* .filter-label:hover {
 cursor: pointer;
} */

/* .square-icn {
    margin-bottom: 2px;
    content: "\2713";
    margin-right: 5px;
    accent-color: white;
    width: 1rem;
    height: 1rem;
    border-radius: 2px;
    border: 1px solid rgba(63, 188, 232, 1);
}
*/
.square-icn {
    appearance: none;
    width: 20px;
    height: 20px;
    /* background-color: #f0f0f0; */
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 2px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.square-icn:hover {
    border-color: #888;
}

.square-icn:checked {
    background-color: transparent;
    border-color: rgba(63, 188, 232, 1);
}

.square-icn:checked::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 6px;
    width: 6px;
    height: 10px;
    border: 1px solid rgba(63, 188, 232, 1);
    /* Green checkmark color */
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.square-icn:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
    opacity: 0.6;
}

.header-username,
.username-box {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 1rem;
}

.user-details {
    width: 100%;
    max-height: 100vh;
    padding-left: 1.5%;
    padding-right: 1.5%;
    margin-top: 0px;
    text-align: left;
    overflow-y: auto;
    padding-bottom: 0.5rem;
    scrollbar-width: thin;
}

.user-table thead {
    background-color: #FAFCFE;
    color: #787988;
    font-family: "Poppins";
    z-index: 100;
    font-size: 1rem;
    position: sticky;
    top: 0;
    font-weight: 700;
}

.user-table {
    overflow-y: scroll;
    border-spacing: 0px 0.7rem;
    border-collapse: separate;
    width: 100%;
    scrollbar-width: thin;
}

.user-table th:nth-child(1),
.user-table td:nth-child(1) {
    width: 20%;
}

.user-table th:nth-child(2),
.user-table td:nth-child(2) {
    width: 35%;
}

.user-table th:nth-child(3),
.user-table td:nth-child(3) {
    width: 23%;
}

.user-table th:nth-child(4),
.user-table td:nth-child(4) {
    width: 22%;
}

.userdetails-body {
    text-align: left;
    margin-left: 3rem;
    font-family: "Poppins";
    font-size: 0.8rem;
    font-weight: 400;
    color: #293042;
}


.thead-row {
    height: 2rem;
    outline: 1px solid #E2E2E7;
}

.action-button {
    background: #FAF6FA;
    color: #955B9A;
    box-shadow: 0px 0px 2px 0px #955B9A40;
    border-radius: 5px;
    border: 1px solid #955B9A4D;
    padding: 3px 5px;
    font-size: 0.8rem;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    width: 7rem;
    height:1.9rem;
}

.pagination {
    display: flex;
    justify-content: right;
    padding-left: 0;
    list-style: none;
    margin-top: 0.2rem;
}

.pagination li {
    margin: 0 5px;
}

.pagination li:hover {
    color: white;
}

.pagination li a {
    display: inline-block;
    padding: 3px 10px;
    background-color: white;
    color: #2D364F;
    border: 1px solid #E3E3E7;
    border-radius: 8px;
    font-weight: 600;
    font-size: 12px;
    text-decoration: none;
    transition: 0.3s ease, color 0.3s ease;
}

.pagination li a:hover {
    background-color: #3FBCE8;
    color: white;
    cursor: pointer;
}

.pagination li.active a {
    background-color: #3FBCE8;
    color: white;
    border-color: #3FBCE8;
}

.pagination-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: sticky;
    margin: 0.4rem;
}

.results-label {
    font-size: 11px;
    font-weight: 600;
    color: #15171880;
}

.page-size-dropdown {
    border: 1px solid #E3E3E7;
    padding: 2px 2px;
    border-radius: 6px;
    font-size: 11px;
    box-shadow: 0px 0px 2px 0px #00000026;
    color: #2D364FCC;
    margin-left: 0.5rem;
}

.filter-dropdown::before {
    content: "";
    position: absolute;
    top: -10px;
    right: 30px;
    border-width: 0 10px 10px 10px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
}

.filter-dropdown {
    position: absolute;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10000;
    padding: 0.9rem 0.9rem 0rem 0.9rem;
    margin-top: 0.8rem;
    margin-left: -1.5rem;
}

.loggedIn-dropdown {
    position: absolute;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 0.9rem 0.9rem 0rem 0.9rem;
    margin: 0;
    cursor: pointer;
    top: 0;
    left: 0;
}



.filter-dropdown ul {
    box-shadow: 0px 32px 48px -8px #0000001A;
    box-shadow: 0px 0px 14px -4px #0000000D;
    backdrop-filter: blur(32px);
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    align-items: flex-start;
    padding-left: 0.5rem;
}

.filter-option {
    list-style: none;
    padding: 0.5rem;
    color: #6C757D;
    width: 100%;
}

.filter-option:hover {
    background: #f2f2f2;
    cursor: pointer;
}

.txt-nousers {
    color: gray;
    font-weight: 600;
    font-size: 1em;
    margin-top: 50px;
}

.noUserContent {
    text-align: center;
}

.show {
    margin-left: 4rem;
    margin-top: 1.3rem;
}

.dropdown-menu.show {
    display: flex;
    max-width: 46%;
    padding: 11px;
    text-overflow: ellipsis;
}

.ok-button {
    background-color: #3FBCE8;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 1rem 0;
}

.selected-filters {
    display: flex;
    font-size: 12px;
    margin-top: 0.6rem;
}

.selected-filter {
    border: 1px solid #F62626;
    color: #293042;
    border-radius: 4px;
    background-color: #FFF5FB;
    padding-left: 0.6rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    margin-left: 2rem;
}

.cancel-icon {
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    color: black;
}


/* Add user */

.adduserPop {
    background: #D9D9D9F2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.addUsersEnable .addusers {
    visibility: visible;
}

.addUsersDisable .addusers {
    visibility: hidden;
}

.addUsersEnable .noUsers {
    visibility: hidden;
}

.addUsersDisable .noUsers {
    visibility: visible;
}

.noUsers {
    background-color: white;
    padding: 0.5rem 1rem;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: relative;
    width: 30rem;
    /* height: 35%; */
}

.addusers {
    background-color: white;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    position: relative;
    width: 45rem;
}

.adduser-table,
.fetchuser-table {
    width: 100%;
    /* margin-top: 1rem; */
}

.adduser-table th,
.adduser-table td,
.fetchuser-table th,
.fetchuser-table td {
    padding: 0.5rem;
    text-align: left;
}

.adduser-table tbody,
.fetchuser-table tbody {
    display: block;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    position: relative;
    border-collapse: collapse;
    border-spacing: 10px;
    scrollbar-width: thin;
}

.adduser-table thead,
.adduser-table tbody tr,
.fetchuser-table thead,
.fetchuser-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.addUser-header {
    height: 2rem;
    outline: 1px solid #3FBCE8;
}

.sent-mark {
    height: 14px;
    width: 14px;
}

.reauthenticate-btn {
    background: #FAF6FA;
    color: #955B9A;
    box-shadow: 0px 0px 2px 0px #955B9A40;
    border-radius: 5px;
    border: 1px solid #955B9A4D;
    text-align: center;
    padding: 1px 20px;
    width:7rem;
    height:1.9rem;
}
.reauthenticate-btn-select{
    border-radius: 50px;
    text-align: center;
}
.sent-btn {
    color: #14a772;
    font-size: 15px;
    margin-top: 3px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.slack-admin{
    cursor: pointer;
    color: #955B9A;
    pointer-events: all;
}
.revoke-admin {
    background: #FAF6FA;
    color:#d8c8d9;
    border: 1px solid #955B9A4D;
    border-radius: 5px;
    width: 7rem;
    text-align: center;
    height: 1.9rem;
    cursor: not-allowed;
}

button {
    text-decoration: none;
    border: none;
}

.userEmail {
    margin-right: 10px;
}

.addusername-box {
    width: 100%;
}

.adduser-table td:nth-child(1) {
    width: 100%;
}

.adduser-table td:nth-child(2) {
    width: 25%;
}

.adduser-table td:nth-child(3) {
    width: 40%;
}

.adduser-table td {
    font-size: 0.8rem;
}

.adduser-table td:nth-child(4) {
    width: 20%;
    text-align: end;
}

.adduserpagination-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: sticky;
    margin: 0.4rem;
    width: 40rem;
}

.initials {
    position: relative;
    z-index: 1;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    background-color: #ecb22e;
    display: flex;
    border: 1px solid #fff;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
}

.adduserplus {
    width: 1em;
    cursor:pointer;
}

.fetchuser-table td:nth-child(1) {
    width: 7%;
}

.fetchuser-table td:nth-child(2) {
    width: 30%;
}

.fetchuser-table td:nth-child(3) {
    width: 60%;
}

.bulk-action {
    color: #3FBCE8;
    margin-top: 0.9rem;
    font-family: "Poppins";
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
    cursor: pointer;
    gap: 5px;
}

.caret-icon {
    color: #8bdcf9;
    margin-top: 0.2rem
}

.ellipses {
    color: rgba(128, 128, 128, 1);
    height: 1.4rem;
    align-self: center;
    cursor: pointer;
    padding-bottom: 3px;
}
.key-userName{
    max-width: 100%;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}
.adduser-search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.searchnames {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2rem;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
}

.addSearch-input {
    flex-grow: 1;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    border: none;
    background: none;
    border-radius: 5px;
    outline: 1px solid #3FBCE8;
    padding: 5px;
    margin-left: 5px;
}
.noUsersText{
    color: gray;
    font-weight: 600;
    font-size: 1em;  
    margin-top: 5px; 
}
.search-input {
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
    border: none;
    background: none;
    outline: none;
    padding: 5px;
    margin-left: 5px;
    width:100%;
}

.close {
    cursor: pointer;
    color: rgba(41, 48, 66, 1);
    margin-left: 10px;
}

.close img {
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.migratedIcon{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 993px) and (max-width: 1366px) {

    .bulk-action {
        margin-left: 2rem;
    }

    .user-table {
        border-spacing: 0px 0.6rem;
    }

    .user-details {
        height: 43vh;
    }

    .userdetails-td:hover {
        opacity: 0.1px;
        background-color: rgb(212, 210, 210);
    }

    .menu-dropdown {
        margin-left: 5%;
        margin-top: 3%;
    }
    .user-search {
        width: 52%;
    }

    .selected-filters {

        margin-top: 0.8rem;
    }

    .import {
        width: 40%;
        height: 31%;
        gap: 0.4rem;
    }
    .reauthenticate-btn{
        width: 7rem;
    }
    .reauthenticate-btn-select{
        width: 7rem;
    }
    .dropdown-select{
        width: 100%;
    }
    .revoke-admin{
        width: 7rem;
        text-align: center;
    }
}
.arrow-logo{
  margin-top: 4px;
}
@media only screen and (min-width: 1367px) {
    .bulk-action {
        margin-left: 17rem;
    }

    .user-table {
        border-spacing: 0px 0.8rem;
    }

    .user-details {
        height: 63vh;
    }
    .activity-feed{
        width: 36rem;
    }
    .menu-dropdown {
        margin-left: 3%;
        margin-top: 2.5%;
    }

    .user-search {
        width: 55%;
    }

    .second-nav-middle {
        width: 70%
    }

    .second-nav-right {
        width: 10%
    }

    .second-nav-left {
        padding-top: 2px;
        margin-top: 3px;
        width: 35%;
    }

    .selected-filters {

        margin-top: 0.4rem;
    }

    .import {
        width: 38%;
        height: 25%;
        gap: 1rem
    }
    .reauthenticate-btn{
        width: 7rem;
    }
    .dropdown-select{
        width: 100%;
    }
    .revoke-admin{
        width: 7rem;
    }
    .sent-btn{
        height: 1.9rem;
        width: 7rem;
    }
    
}
.userdetails-td {
    margin: 2px;
    height: 1.8rem;
    max-height: 1.8rem;
}
.bulk-action-dropdown::before {
    content: "";
    position: absolute;
    top: -10px;
    right: 30px;
    border-width: 0 10px 10px 10px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
}

.bulk-action-dropdown {
    position: absolute;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 999;
    padding: 0.9rem 0.9rem 0rem 0.9rem;
    margin-top: 2rem;
    margin-left: -1.5rem;
}

.bulk-action-dropdown ul {
    box-shadow: 0px 32px 48px -8px #0000001A;
    box-shadow: 0px 0px 14px -4px #0000000D;
    backdrop-filter: blur(32px);
    list-style: none;
}

.bulk-action-option,
.loggedIn-option {
    list-style: none;
    padding: 0.5rem;
    color: black;
    font-size: 14px;
    font-weight: 400;

}

.switchusers {
    background: rgba(244, 245, 245, 1);
    padding: 0.5rem 1rem;
    border-radius: 5px;
}

.switch-Account {
    display: flex;

}

.dropdown-item {
    transition: background-color 3ms ease;
}

.dropdown-item:active {
    background-color: #3FBCE8;
}

.menu-option {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    display: inline-block;
    /* text-align: center; */
}
.dropdown-menu {
    width: fit-content;
}

.bulk-action-option:hover {
    cursor: pointer;
    color: rgba(63, 188, 232, 1)
}

.album {
    align-items: center;
    width: 1.3rem;
    height: 1.3rem;
}

.menu-option {
    text-align: center;
    padding: 0;
}

/* .download {
    display: flex;
    gap: 0.4rem;
} */

.add-user {
    display: flex;
    gap: 0.4rem;
    flex-direction: row;
    align-items: center;
}


.clear-all {
    width: 75px;
    margin: 10px 10px 0 0;

}

.loggedIn-userName {
    cursor: pointer;
}

.import-popup {
    font-family: "Poppins";
    width: 100%;
    height: 100%;
    color: rgba(128, 128, 128, 1);
    background: rgba(217, 217, 217, 0.92);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
}

.import {
    background-color: white;
    padding: 0.5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
}

.folder {
    color: rgba(63, 188, 232, 1);
    width: 2rem;
    height: 1.8rem;
}

.folder-input {
    width: 100%;
    height: 100%;
    padding: 8px;
    border: 1px solid rgba(217, 217, 217, 0.88);
    height: 3rem;
    /* margin-bottom: 1.5rem; */
    font-size: small;
}

.importerror-msg {
    font-size: 13px;
    color: red;
    font-weight: bold;
    text-align: center;
}

.Name {
    font-size: 13px;
    font-weight: 500;
}
.folder-input::-webkit-file-upload-button {
    margin-right: 20px;
  }
.folder-title {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid rgba(217, 217, 217, 0.88);
    display: flex;
    justify-content: space-between;
}

.folder-title-icon {
    display: flex;
    gap: 0.5rem;
}

.search-container {
    display: flex;
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
}

.add-user-checkbox {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
}

.dropdown-select {
    /* appearance: none; */
    cursor: pointer;
    background: #FAF6FA;
    color: #955B9A;
    box-shadow: 0px 0px 2px 0px #955B9A40;
    border-radius: 5px;
    border: 1px solid #955B9A4D;
    padding: 3px 5px;
    font-size: 0.8rem;
    font-weight: 400;
    text-align: center;
    width: 7rem;
    height:1.9rem;
}

.action-option:hover {
    text-align: center;
    background: #955B9A;
    color: white;
    cursor: pointer;
}