@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.footer {
    font-family: "Poppins", sans-serif;
    text-align: center;
    padding: 1rem;
    color: #4e4b4b;
    font-size: 0.55rem;
    position: absolute;
    bottom: 0;
    width: 100%;
}
@media (max-width: 767px) {
    .footer{
        position: relative;
        bottom: auto;
    }
}
@media (max-height:468px){
    .footer{
        bottom: auto;
    }
}
