body {
    background: rgba(248, 248, 248, 1);
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background-color: #F8F8F8
}
.slack-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10%;
    padding: 0 1rem;
}
.slacklogo {
    width: 2rem;
    height: 2rem;
}

.slack-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    padding: 0 1rem;
}

.slacklogin {
    padding:0.8rem 2rem 3rem 2rem;
    max-width: 500px; 
    display:flex;
    flex-direction: column;
    border-radius: 2%;
    box-shadow: 0px 0px 0px 0px rgba(33, 33, 33, 0.03),
                0px 3px 6px 0px rgba(33, 33, 33, 0.03),
                0px 11px 11px 0px rgba(33, 33, 33, 0.03),
                0px 25px 15px 0px rgba(33, 33, 33, 0.02),
                0px 44px 18px 0px rgba(33, 33, 33, 0.01),
                0px 69px 19px 0px rgba(33, 33, 33, 0);
}

.slackbutton,.username,.connect {
    padding: 9px 7px;
    font-size: 12px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgba(204, 204, 204, 1);
    /* margin-top: 1rem; */
    position: relative;
    overflow: hidden;
    /* cursor: pointer; */
    transition: all 0.3s ease-in-out;
}
.key-box {
    background-color: #f5f5f5;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 11px;
    overflow-x: auto;
    text-decoration: none;
     cursor: pointer;
     overflow: hidden;
     text-overflow: ellipsis;
      white-space: nowrap; 
      display: inline-block; 
}
.username,.connect{
    padding: 4px 7px;
    margin-top: 0;
    border-radius: 5px;
    width:10rem
}
.connect{
    background-color:  rgba(63, 188, 232, 1);
    color: White;
    width: 5rem;
}
select:disabled {
    opacity: 0.5;
}
.workspaceCheck{
    margin-top: 10px;
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    gap: 1rem
}


.slackbutton::before,
.slackbutton::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;
    border: 1.5px solid transparent;
    box-sizing: border-box;
    transition: all 0.4s ease;
}

.slackbutton::before {
    border-right-color: rgba(63, 188, 232, 1);
    border-top-color: rgba(63, 188, 232, 1);
    transform: scaleX(0);
    transform-origin: right;
}

.slackbutton::after {
    border-bottom-color: rgba(63, 188, 232, 1);    
    border-left-color: rgba(63, 188, 232, 1);
    transform: scaleY(0);
    transform-origin: bottom;
}

.slackbutton:hover::before {
    transform: scaleZ(1);
}

.slackbutton:hover::after {
    transform: scaleY(1);
}

.signin {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 10px;
}

.slack {
    font-weight: 600;
}

.slacktext {
    font-size: 0.8rem; /* Make text a bit smaller */
}

.slackloginsuccess {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(217, 217, 217, 0.92);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; 
    gap: 5px;
}

.slacksuccessgif {
    width: 3rem;
    height: 3rem;
    margin-right: 10px;
}
.successText{
    font-size: 1em;
}
.slack-error-msg{
    display: flex;
    justify-content: center;
    margin-top: 3%;
    font-size: 13px;
    color: red;
    font-weight: bold;
}

.slack-login {
    padding-top: 0.5rem;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
    gap: 2.5rem;
    width: 44.5%;
    
 }
 .donut{
    z-index:999;
 }

 .slack-login-name {
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    gap:5px;
    font-size: 1rem;
    line-height: 21px;
    text-align: center;
    font-weight: 600;
    color:rgb(7,147, 196);
    overflow-x: auto;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; 
 }
 .pie-container {
    background-color: #DBDBDB2E;
    width: 54%;
    padding-left: 10%;
    margin-left: 22%;
    border-radius: 10%;
 }

 @media (min-width: 1380px) {
    .slacklogin {
        width: fit-content;
        height: fit-content;
        padding: 2rem 3rem;
    }
    /* .slack-container {
        margin-top: 25%;
    } */
    .signin {
        font-size: 1.8rem;
        margin-bottom: 0.5rem;
    }
    .slacklogo {
        width: 3rem;
        height: 3rem;
    }
    .slacktext {
        font-size: o.6rem;
        margin-bottom: 0.6rem;
    }
    .slackbutton {
        padding: 0.4rem 0.8rem;
        font-size: 1rem;
    }
 }
@media (min-width: 1536px) {
    .pie-container {
        width:60% ;
        padding-left: 15%;
    }
 }
 @media (min-width: 768px) and (max-width: 1024px){
    .pie-container {
        width:60% ;
        padding-left: 6%;
    }
}
@media (min-height: 860px){
    .slack-login {
        padding-top: 9.5rem;
    }
}
@media (max-width: 768px) {
    .slacklogin {
        padding: 1rem 1.5rem;
        max-width: 50%; 
    }
    .signin {
        font-size: 1rem; 
    }
    .slacktext {
        font-size: 0.6rem;
    }
    .slackbutton {
        font-size: 11px;
    }
 }
.dropdown-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    list-style: none;
    margin: 0;
    z-index: 1000;
  }
  .dropdown:hover .dropdown-menu {
    display: block;
  }

  .dropdown-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
 @media (max-width: 480px) {
    .slacklogin {
        padding: 1rem 1.5rem;
    }
    .signin {
        font-size: 1rem;
    }
    .slacklogo {
        width: 1.5rem;
        height: 1.5rem;
    }
    .slackbutton {
        font-size: 10px;
        padding: 8px 5px;
    }
 }
 .slack-logout-confirmation{
    width: 100vw;
    height: 100vh;
    background: rgba(217, 217, 217, 0.92);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999; 
    gap: 5px;
 }