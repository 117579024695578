.dashboard{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #D9D9D9A1;
  z-index: 90;
}
.teams-and-slack.active{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid blue;
}
.teams-and-slack.inactive{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.slack-title {
    font-family: "Poppins";
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
    margin-left: 5%;
}
.teams-slack {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.slack-logged {
    display: flex;
    flex-direction: row;
    padding-left: 8rem;
    align-items: center;
 }
@media (min-width: 1380px){
    .slack-title {
        font-weight: 500;
        margin-top: 5%;
        margin-bottom: 1%;
    }
    .teams-slack{
        margin-top: 5%;
    }
    .slack-logged {
        padding-left: 11rem;
    }
}