@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: "Poppins", sans-serif;
}

header {
    background: linear-gradient(90deg, #236982 0%, #3FBCE8 100%);
    display: flex;
    align-items: center;
    padding: 8px 1rem;
    justify-content: space-between;
}

.headercontent {
    display: flex;
    align-items: center;
}

.avatar {
    background-color: #ffb800;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
}

.Profilelogo {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    font-size: 14px;
    font-weight: 500;
    gap: 0.5rem;
}

.saketaicon {
    width: 100%;
    max-width: 2.5rem;
    height: auto;
    margin-right: 6px;
}

.saketatext {
    font-size: calc(0.6rem + 1vw);
    font-weight: 500;
    color: white;
}

/* @media (min-width: 1380px) {


} */
@media (min-width: 768px) and (max-width: 1379px) {
    .account-name {
        font-size: 15px;
    }
    .migration-title {
        width: 8vw;
        font-weight: bold;
    }
    .token-popup{
        width: 30%;
    }
    .generate-token-btn{
        width:50% !important;
        margin-left: 25% !important;
    }

}
@media (min-width: 1380px) {
    .migration-title {
        width: 5.5vw;
        font-weight: bold;
    }
    .token-popup{
        width: 24%;
    }
   
}
.TooltipMigration .tooltiptext-migration {
    visibility: hidden;
    width: 10rem;
    font-size: 11px;
    border: 1px solid rgba(63, 188, 232, 1);
    background-color: white;
    color: rgba(128, 128, 128, 1);
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    margin-top: -20px;
}
.TooltipMigration:hover .tooltiptext-migration {
    visibility: visible;
}
.token-popup {
    top: 30%;
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow:
        10px 0 10px rgba(0, 0, 0, 0.1),
        -10px 0 10px rgba(0, 0, 0, 0.1),
        0 10px 10px rgba(0, 0, 0, 0.1),
        0 -10px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    display: flex;
    flex-direction: column;
}

.key-container {
    margin: 20px 0;
    max-width: 100%;
    overflow-x: hidden;
}

.key-box {
    max-width: 100%;
    background-color: #f5f5f5;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}
.migration-container{
    display: flex;
    flex-direction: row;
}
.closeIcon {
    display: flex;
    align-self: flex-end;
    margin-bottom: 20px;
    cursor: pointer;
}


.popup-line {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content:center;
    margin-bottom: 15px;
    font-size: small;
}

.popup-line label {
    font-weight: bold;
}

.generate-token-btn,
.copy-btn {
    background-color: rgb(8, 152, 204);
    color: white;
    border: none;
    width: 40%;
    margin-left: 30%;
    padding: 8px 15px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 8px;
}

.generate-token-btn:hover,
.copy-btn:hover {
    background-color: #0077a3;
}

.migration-key {
    font-size: 14px;
    font-weight: bold;
    color: rgb(8, 152, 204);
}


.key-error-msg {
    display: block;
    text-align: center;
    font-weight: bold;
    color: rgb(245, 38, 38);
}
.migration-profile{
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    align-items: center;
}
.migration{
color: rgb(242, 245, 248);
display: flex;
flex-direction: row;
gap: 0.5rem;
align-items: center;
cursor: pointer;
}