@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    height: 100vh;
    overflow: hidden;
}

.s2t-main-screen {
    display: flex;
    justify-content: flex-start;
    height: 100vh;
    width: 100vw;
}

/* .left-section, .right-section {
    width: 50%;
    height: 100vh;
}
.eclipse-left, .eclipse-right {
    width: 25%;
    height: 20%;
}

.eclipse-right {
    float: right;
    margin-bottom: 2em;
    margin-right: 2em;
}

.eclipse-left {
    margin-top: 2em;
    margin-left: 2em;
}
.left-section {
    background: linear-gradient(140.93deg, rgba(36, 146, 113, 0.85) 2.58%, rgba(36, 146, 113, 0.85) 17.68%, rgba(36, 146, 113, 0.85) 26.11%, rgba(36, 146, 113, 0.85) 33.85%, rgba(63, 188, 232, 0.85) 100%);

}
.teamslack-rotate {
    display: block;
    margin: auto;
    width: 30%;
    height: 30%;
}
.contenttext{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1.5em;
}
.content{
    margin: 1em 5em;
    font-size: 0.8em;
    gap:1em;
    color: #FFFFFF;
    margin: 0 2em;
    display: flex;
    flex-direction: column;
}
.content-2{
    font-size: 0.7em;
    margin-bottom: 3em;
    font-style: italic;
} */
.right-section {
    background-color: #F4F5F5;
}

.microsoftlogo {
    width: 1em;
    height: 1;
    margin-right: 5px;
}

.saketalogo {
    width: 36px ;
    height:36px;
    margin-left: 1rem;
    margin-right: 6px;
    margin-bottom: 4px;
}

.slackteamlogo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.saketaheader {
    font-size: 1.6rem;
    font-weight: 600;
}

.loginheader {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
}

.login-data,
.login-success,
.login-failed {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 21%;
    line-height: 2rem;
    letter-spacing: 2%;
}

.slacktoteamsimage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
}

.slackimage {
    width: 7rem;
    height: 7rem;
}

.toimage {
    width: 5rem;
    margin-left: 1em;
    margin-right: 2em;
    height: 4rem;
}

.teamsimage {
    width: 4rem;
    height: 4rem;
}

.note {
    border: 1px solid white;
    width: 56%;
    height: fit-content;
    padding: 8px;
    margin-top: 16px;
    box-shadow: 0px 12px 8px -8px rgba(0, 0, 0, 0.2), 0px 13px 20px 0 rgba(0, 0, 0, 0.19);
    line-height: 1.2rem;
    font-size: 13px;
}

.acc-login {
    font-size: 1.8em;
    font-weight: 500;
    color: rgba(41, 48, 66, 1);
}

.acc-desc {
    padding-bottom: 1rem;
    font-size: 0.8em;
    color: rgba(41, 48, 66, 1);
    padding-left: 1.6rem;
    margin: auto;
    font-style: italic;
}

.login {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 12px 8px;
    border-radius: 10px;
    border: 1px solid #CCCCCC;
    font-size: 14px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.error-msg {
    display: block;
    align-items: center;
    justify-content: center;
    color: brown;
}

.login::before,
.login::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    border: 1.5px solid transparent;
    box-sizing: border-box;
    transition: all 0.4s ease;
}

.login::before {
    border-right-color: rgba(63, 188, 232, 1);
    border-top-color: rgba(63, 188, 232, 1);
    transform: scaleX(0);
    transform-origin: right;
}

.login::after {
    border-bottom-color: rgba(63, 188, 232, 1);
    border-left-color: rgba(63, 188, 232, 1);
    transform: scaleY(0);
    transform-origin: bottom;
}

.login:hover::before {
    transform: scaleZ(1);
}

.login:hover::after {
    transform: scaleY(1);
}

.successgif,
.failedgif {
    width: 2.2rem;
    height: 2.2rem;
    margin-left: 2px;
}

.success,
.authfail {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    font-weight: 500;
}

@media (max-width: 1379px) and (min-width: 758px) {
    .storage-confirm {
        width: 57%;
    }

    .temp-popup {
        width: 20%;
        top: 20%;
    }

    .dropdown-container {
        width: 50%;
    }

}

select:disabled {
    opacity: 0.5;
}

.asterisk {
    color: red;
    font-size: large;
    padding-left: 2px;
}

@media (min-width:1380px) {
    .storage-confirm {
        width: 38%
    }

    .temp-popup {
        width: 13%;
        top: 35%;
    }

}

.temp-heading {
    font-size: 0.8rem;
}
.region-dropdown{
    font-size: 0.7rem;
    border: 1px solid #ccc;
    padding: 4px;
    border-radius: 6px;
    gap: 10px;
    background-color: #fff;
    transition: all 0.3s ease;
    width: 100%;
}

/* @media (max-width: 1024px)  and (max-height: 758px){
    .acc-login{
        font-size: 1em;
    }
    .content{
        gap: 1rem;
        font-size: 0.6em;
    }
    .content-2{
        font-size: 0.5em;
        margin-bottom: 2em;
    }
    .eclipse-left, .eclipse-right {
        width: 18%;
        height: 13%;
    }
    .teamslack-rotate {
        width: 20%;
        height: 20%;
    }
}
@media (min-width: 1264px)  {
    .slackimage{
        width: 7rem;
        height: 7rem;
    }
    .toimage{
        width: 5rem;
        margin-left: 1em;
        margin-right: 2em;
        height: 4rem;
    }
    .teamsimage{
        width: 4rem;
        height: 4rem;
    }
    .slacktoteamsimage{
        margin-top: 1em;
    }
    .left-section, .right-section {
        width:50%
    }
    .contenttext {
        color: #FFFFFF;
        font-weight: 600;
        font-size: 1.3em;
    }
    .content{
        margin: 1em 5em;
        font-size: 1em;
    }
    .content-2{
        font-size: 0.8em;
        margin-bottom: 1em;
    }
    .eclipse-left, .eclipse-right {
        width: 20%;
        height: 19%;
    }
} */

.left-section,
.right-section {
    width: 50%;
    height: 100vh;
}

.eclipse-left,
.eclipse-right {
    width: 22%;
    height: 18%;
}

.eclipse-right {
    float: right;
    margin-bottom: 2em;
    margin-right: 2em;
}

.eclipse-left {
    margin-top: 2em;
    margin-left: 2em;
}

.left-section {
    background: linear-gradient(140.93deg, rgba(36, 146, 113, 0.85) 2.58%, rgba(36, 146, 113, 0.85) 17.68%, rgba(36, 146, 113, 0.85) 26.11%, rgba(36, 146, 113, 0.85) 33.85%, rgba(63, 188, 232, 0.85) 100%);
}

.contenttext {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1.5em;
}

.content {
    margin: 1em 5em;
    font-size: 0.8em;
    gap: 1em;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
}

.content-2 {
    font-size: 0.8em;
    margin-bottom: 3em;
    font-style: italic;
}

@media (min-width: 1380px) {
    .contenttext {
        font-size: 1.7em;
    }
    .note{
        width: 46%;
    }
    .content {
        margin: 2em 7em;
        font-size: 1.2em;
        gap: 2em;
    }

    .slacktoteamsimage {
        margin-top: 2em;
    }

    .eclipse-left,
    .eclipse-right {
        width: 22%;
        height: 20%;
    }

    .slackimage {
        width: 10rem;
        height: 10rem;
    }

    .toimage {
        width: 8rem;
        margin-left: 1em;
        margin-right: 2em;
        height: 7rem;
    }

    .teamsimage {
        width: 6rem;
        height: 6rem;
    }

}

@media (max-width: 768px) {
    .slackteamlogo {
        width: 30%;
        height: 30%;
        left: 32%;
        top: 33%;
    }
}

@media (max-height: 349px) {

    .login-data,
    .login-success,
    .login-failed {
        margin-top: 10%;
        line-height: 1rem;
        letter-spacing: 2%;
    }
}

@media (max-height: 214px) {

    .login-data,
    .login-success,
    .login-failed {
        margin-top: 8%;
        line-height: 0.6rem;
        letter-spacing: 2%;
    }

    .success,
    .authfail {
        font-size: 6px;
        font-weight: 500;
    }

    .successgif,
    .failedgif {
        width: 0.8rem;
        height: 0.8rem;
        margin-left: 2px;
    }

    .left-section {
        background-color: red;
    }

    .login {
        padding: 3px 2px;
        border-radius: 10px;
        border: 1px solid #CCCCCC;
        font-size: 5px;
    }

    .acc-login {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 1.3rem;
        color: #293042;
    }

    .saketalogo {
        margin-left: 0.8rem;
        margin-right: 6px;
    }

    .saketaheader {
        font-size: 10px;
    }
}

@media (max-height: 151px) {

    .login-data,
    .login-success,
    .login-failed {
        margin-top: 2%;
        line-height: 0.2rem;
        letter-spacing: 2%;
    }

    .login {
        padding: 5px 3px;
        border-radius: 10px;
        border: 1px solid #CCCCCC;
        font-size: 5px;
    }

    .acc-login {
        font-size: 8px;
        font-weight: 400;
        margin-bottom: 1rem;
        color: #293042;
    }
}

.spinner-gif {
    width: 70px;
    height: 70px;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
}

.error-msg {
    margin-left: 15%;
    margin-top: 3%;
    font-size: 13px;
    color: red;
    font-weight: bold;
}
.storage {
    width: 100vw;
    height: 100vh;
    background: rgba(217, 217, 217, 0.92);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    gap: 20px;
}

.storage-confirm {
    background-color: white;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    border-radius: 4px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.storage-text {
    margin-top: 1rem;
    font-weight: 600;
    font-size: 1.5em;
    color: rgb(8, 152, 204);
    text-align: center;
}

.storage-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
}

.storage-subcontainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    color: rgb(8, 152, 204);
    width: 100%;
}

.label-title {
    font-size: 1rem;
    font-weight: 500;
    color: rgb(8, 152, 204);
    align-self: center;
    width: 35%;
    display: flex;
    align-items: center;
}

.close {
    cursor: pointer;
    color: rgba(41, 48, 66, 1);
    margin-left: 10px;
}

.close img {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.add-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.account-dropdown {
    padding: 4px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #fff;
    transition: all 0.3s ease;
    width: 100%;
}

.add-btn {
    font-size: 1.5rem;
    background-color: transparent;
    color: rgb(8, 152, 204);
    border: 1px solid rgb(8, 152, 204);
    border-radius: 20%;
    cursor: pointer;
    margin-left: 0.8rem;
    padding: 0 0.5rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-btn:hover {
    background-color: rgb(8, 152, 204);
    color: white;
    transform: scale(1.1);
}

.dropdown-container {
    width: 65%;
}

.temp-popup {
    position: absolute;
    transform: translateX(-50%);
    padding: 1rem;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    gap: 1rem;
    z-index: 1000;
    left: 44%;
}

.temp-popup input {
    padding: 10px;
    font-size: 0.7rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    width: 100%;
}

.temp-popup button {
    padding: 10px 20px;
    background-color: rgb(8, 152, 204);
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.temp-popup button:hover {
    background-color: #0077a3;
}

.temp-buttons {
    display: flex;
    justify-content: end;
    width: 100%;
    gap: 20px;
}

.temp-proceed {
    padding: 12px 20px;
    background-color: rgb(8, 152, 204);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.temp-proceed:hover {
    background-color: #0077a3;
    transform: translateY(-2px);
}

.slack-error-msg {
    color: red;
    font-size: 0.9rem;
    margin-top: 10px;
    text-align: center;
}

.enterprise {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-bottom: 1rem;
    accent-color: rgba(63, 188, 232, 1);
}
.saketa-title{
    display: flex;
    flex-direction: column;
}
.tag-line{
    padding-left: 0.8rem;
    margin-top: -10px;
    font-size: 9px;
}
.enterprise-checkbox {
    appearance: none;
    width: 15px;
    height: 15px;
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 2px;
    position: relative;
    cursor: pointer;
    margin-top: 0.5rem;
    transition: background-color 0.3s, border-color 0.3s;
}

.enterprise-checkbox:hover {
    border-color: #888;
}

.enterprise-checkbox:checked {
    background-color: transparent;
    border-color: rgba(63, 188, 232, 1);
}

.enterprise-checkbox:checked::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 3px;
    width: 5px;
    height: 8px;
    border: 1px solid rgba(63, 188, 232, 1);
    /* Green checkmark color */
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.tooltip-container-temp {
    position: relative;
    display: inline-block;
}

.tooltip-container-temp .tooltip-text-temp {
    visibility: hidden;
    width: 140px;
    border: 1px solid rgba(63, 188, 232, 1);
    background-color: white;
    color: rgba(128, 128, 128, 1);
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    margin-left: 10px;
    margin-bottom: 5px;
    
   
}
.tooltip-container-temp .tooltip-text-temp::after {
    content: "";
    position: absolute;
    top: 55%;
    right: 100%;
    /* padding: 10px; */
    margin-top: -8px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent rgba(63, 188, 232, 1) transparent transparent;
}

.tooltip-container-temp:hover .tooltip-text-temp {
    visibility: visible;
}